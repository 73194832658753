'use client';

import { Flex, Stack, Text, SimpleGrid, Chip, rem, Badge } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button, PageHeader } from '@/components';
import { useRouter } from 'next/navigation';
import classes from './Profile.module.css';
import { useUser } from '@/hooks/useUser';
import { CaretLeft } from '@phosphor-icons/react';

export const Profile = () => {
  const [open, actions] = useDisclosure(false);
  const user = useUser((state) => state.user);
  const router = useRouter();

  const roles = user?.role ? user.role.split(',') : [];

  const handleBack = () => {
    router.push('/home');
  };

  const handleEdit = () => {
    router.push(`/settings/users/details/${user?.clientId}`);
  };

  return (
    <Flex direction="column" gap={rem(24)}>
      <Flex justify="space-between">
        <Flex>
          <Button
            onClick={handleBack}
            variant="transparent"
            c="grey"
            leftSection={<CaretLeft color="grey" />}
          >
            Atpakaļ
          </Button>
          <PageHeader title="Mans profils" />
        </Flex>
        <Flex>
          <Button onClick={handleEdit}>Rediģēt profilu</Button>
        </Flex>
      </Flex>
      <Flex>
        <Stack
          h={250}
          align="stretch"
          justify="flex-start"
          gap="md"
          className={classes.formBox}
          p={rem(30)}
        >
          <Text fw={600} fz={rem(16)}>
            Profila informācija
          </Text>
          <SimpleGrid cols={3}>
            <Text fz={rem(14)} c="grey">
              Vārds, Uzvārds
            </Text>
            <Text fz={rem(16)} fw={600}>
              {user?.name} {user?.surname}
            </Text>
            <div />
            <Text fz={rem(14)} c="grey">
              E-pasts
            </Text>
            <Text fz={rem(16)}>{user?.email}</Text>
            <div />
            <Text fz={rem(14)} c="grey">
              Telefona numurs
            </Text>
            <Text fz={rem(16)}>
              {user?.phoneAreaCode} {user?.phone}
            </Text>
            <div />
            <Text fz={rem(14)} c="grey">
              Lomas
            </Text>
            <Flex gap={rem(8)}>
              {roles.map((role, index) => (
                <Badge key={index} variant="light" color="pink" size="lg">
                  {role}
                </Badge>
              ))}
            </Flex>
          </SimpleGrid>
        </Stack>
      </Flex>
      <Flex>
        <Stack
          h={210}
          align="stretch"
          justify="flex-start"
          gap="md"
          className={classes.formBox}
          p={rem(30)}
        >
          <Text fw={600} fz={rem(16)}>
            Uzņēmuma informācija
          </Text>
          <SimpleGrid cols={3}>
            <Text fz={rem(14)} c="grey">
              Uzņēmuma nosaukums
            </Text>
            <Text fz={rem(16)} fw={600}>
              {user?.company_name}
            </Text>
            <div />
            <Text fz={rem(14)} c="grey">
              Uzņēmuma reģistrācijas numurs
            </Text>
            <Text fz={rem(16)}>{user?.company_number}</Text>
            <div />
            <Text fz={rem(14)} c="grey">
              Uzņēmuma adrese
            </Text>
            <Text fz={rem(16)}>
              {user?.company_street} {user?.company_house}, {user?.company_city}
              , {user?.company_country}
            </Text>
            <div />
          </SimpleGrid>
        </Stack>
      </Flex>
    </Flex>
  );
};
