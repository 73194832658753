'use client';

import { useState } from 'react';
import { Text, rem } from '@mantine/core';
import { Login } from './Login';
import { Register } from './Register';
import Image from 'next/image';
import { RestorePass } from './RestorePass';
import { VIEWS } from './interfaces';
import classes from './Auth.module.css';
import { useTranslation } from '@/hooks';
import splash from '@/public/images/splash.jpg';

const VIEW_COMPONENTS = {
  [VIEWS.LOGIN]: Login,
  [VIEWS.REGISTER]: Register,
  [VIEWS.RESTORE_PASS]: RestorePass,
};

export const Auth = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<VIEWS>(VIEWS.LOGIN);
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;

  const Component = VIEW_COMPONENTS[view];

  return (
    <div className={classes.container}>
      <div>
        <div style={{ position: 'relative' }}>
          <Image
            alt="Splash"
            src={splash}
            style={{ width: '100%', height: '100vh' }}
          />
          <Text
            style={{
              color: '#FFFFFF',
              position: 'absolute',
              top: 20,
              left: 20,
            }}
            fz={rem(50)}
            fw={700}
          >
            etame
          </Text>
        </div>
      </div>
      <div className={classes.form}>
        <Component onViewChange={setView} />
      </div>
    </div>
  );
};
