import { Text } from '@mantine/core';
import { Button } from '@/components';
import { VIEWS } from '../interfaces';

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const RestorePass = ({ onViewChange }: Props) => (
  <>
    <Text fz={150}>TODO</Text>
    <Button variant="transparent" onClick={() => onViewChange(VIEWS.LOGIN)}>
      GO BACK
    </Button>
  </>
);
