'use client';

import { useState } from 'react';
import Image from 'next/image';
import { Box, Flex, Text, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRouter } from 'next/navigation';
import { notifications } from '@mantine/notifications';
import { useTranslation } from '@/hooks';
import classes from './Login.module.css';
import logoSmall from '@/public/images/logo_shorthand_green.svg';
import { Button, TextInput, PasswordField } from '@/components';
import { login } from '@/lib/login';
import { VIEWS } from '../interfaces';

const OK_STATUSES = [200, 201];

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const Login = ({ onViewChange }: Props) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [reject, setReject] = useState(false);
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : t('login.emailInvalid'),
      password: (value: string) => (value ? null : t('login.passwordInvalid')),
    },
  });

  const handleLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      const resp = await login({ ...values });
      if (!OK_STATUSES.includes(resp.status)) {
        notifications.show({
          title: 'Login failed',
          color: 'red',
          message: resp.data,
          autoClose: 5_000,
          withCloseButton: true,
        });
      } else {
        router.push('/home');
        router.refresh();
      }
    } catch (e) {
      setReject(true);
    }

    setLoading(false);
  };

  return (
    <Box className={classes.rightSide}>
      <Flex direction="column" justify="center" align="center">
        <form onSubmit={form.onSubmit(handleLogin)}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            w={300}
            gap={rem(20)}
          >
            <Text style={{ color: 'black' }} fz={rem(30)}>
              Pieslēgties
            </Text>
            <TextInput
              label={'E-pasts'}
              name="email"
              {...form.getInputProps('email')}
              w="100%"
            />
            <PasswordField
              label={'Parole'}
              name="password"
              {...form.getInputProps('password')}
              w="100%"
            />
            {reject ? (
              <Text c="red" fz={rem(12)}>
                Nepareizs e-pasts un/vai parole
              </Text>
            ) : null}
            <Button mt={rem(20)} miw={rem(300)} type="submit" loading={loading}>
              Ienākt
            </Button>

            {/* <Button
              variant="transparent"
              onClick={() => onViewChange(VIEWS.RESTORE_PASS)}
            >
              {t('forgotPass')}
            </Button> */}
          </Flex>
        </form>
      </Flex>
    </Box>
  );
};
