import { useUser } from '@/hooks/useUser';
import { User } from '@/interfaces/user';
import { errorNotification, getUserAttribute } from '@/util';
import { httpClient } from '@/util/httpClient';

export const getUser = async (client_id?: any) => {
  try {
    const resp = await httpClient().get(`/clients/client/${client_id}`);

    if (resp.status === 200) {
      const user: User = {
        clientId: resp.data.id,
        email: resp.data.email,
        name: resp.data.first_name,
        surname: resp.data.last_name,
        phone: resp.data.phone,
        status: resp.data.status,
        role: resp.data.role,
        phoneAreaCode: resp.data.phone_area_code,
        company_name: resp.data.company_name,
        company_number: resp.data.company_number,
        company_country: resp.data.company_country,
        company_city: resp.data.company_city,
        company_street: resp.data.company_street,
        company_house: resp.data.company_house,
      };

      useUser.getState().setUser(user);
    }
  } catch (error) {
    errorNotification('Failed to get user data');
    console.error(error);
  }
};
