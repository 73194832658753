'use client';

import dayjs from 'dayjs';
import { Flex, rem, Grid } from '@mantine/core';
import { useTranslation } from '@/hooks';
import { useRouter } from 'next/navigation';
import classes from './Settings.module.scss';
import { CaretLeft } from '@phosphor-icons/react';
import { SimpleStatBlock, PageHeader } from '@/components';
import { RoleSelector } from '@/components/RoleSelector';
import { useStore } from '@/hooks/store';
import { AddUser } from './addUser';
import { Button } from '@/components';

export const Settings = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentRole, selectRole } = useStore();

  const handleBack = () => {
    router.push('/materials');
  };

  return (
    <Flex direction="column" gap={rem(24)}>
      <Flex justify="space-between">
        <Flex>
          <Button
            onClick={handleBack}
            variant="transparent"
            c="grey"
            leftSection={<CaretLeft color="grey" />}
          >
            Atpakaļ
          </Button>
          <PageHeader title={'Administratora iestatījumi'} />
        </Flex>
      </Flex>
      <AddUser />
    </Flex>
  );
};
