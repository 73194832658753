'use client';

import dayjs from 'dayjs';
import {
  Flex,
  rem,
  NumberFormatter,
  Button,
  FileButton,
  ActionIcon,
  Group,
  Text,
} from '@mantine/core';
import useSWR from 'swr';
import Image from 'next/image';
import { useTranslation } from '@/hooks';
import { useRouter } from 'next/navigation';
import classes from './Home.module.scss';
import { SimpleStatBlock, PageHeader } from '@/components';
import { UserTraffic } from '@/components/UserTraffic';
import { uploadDocument } from '@/lib/uploadDocument';
import { useState } from 'react';
import { Archive } from '@phosphor-icons/react/dist/ssr';

export const Home = () => {
  const { t } = useTranslation();
  const router = useRouter();

  // const [file, setFile] = useState<File | null>(null);

  // const encodeToBase64 = (file: File) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result.split(',')[1]);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  // const handleFileChange = async (file: File) => {
  //   setFile(file);
  //   if (file) {
  //     try {
  //       const base64Content = await encodeToBase64(file);
  //       await uploadDocument(base64Content, file);
  //     } catch (error) {
  //       console.error('Error encoding file to base64:', error);
  //     }
  //   }
  // };

  return (
    <Flex direction="column">
      <div
        className={classes.Heading}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <PageHeader title={t('home')} />
      </div>

      {/* <Group justify="center">
        <FileButton onChange={handleFileChange} accept="image/png,image/jpeg,application/pdf">
          {(props) => <Button {...props}>Upload document</Button>}
        </FileButton>
      </Group>

      {file && (
        <Text size="sm" ta="center" mt="sm">
          Picked file: {file.name}
        </Text>
      )} */}
      <Flex w="100%" gap={rem(24)} mb={rem(24)}>
        <div className={classes.grid}>
          <SimpleStatBlock
            title="Objekta nosaukums 1"
            rows={[
              {
                name: '',
                value: (
                  <NumberFormatter
                    prefix="€"
                    value={239110}
                    thousandSeparator
                  />
                ),
                date: '08.07.2024',
              },
            ]}
            light
          />
          <SimpleStatBlock
            title="Objekta nosaukums 2"
            rows={[
              {
                name: ':',
                value: (
                  <NumberFormatter
                    prefix="€"
                    value={391090}
                    thousandSeparator
                  />
                ),
                date: '08.07.2024',
              },
            ]}
          />
          <SimpleStatBlock
            title="Objekta nosaukums 3"
            rows={[
              {
                name: 'Kopā:',
                value: (
                  <NumberFormatter
                    prefix="€"
                    value={455019}
                    thousandSeparator
                  />
                ),
                date: '08.07.2024',
              },
            ]}
            light
          />
          <Button
            w="100%"
            h={rem(138)}
            bg="#000000"
            fz={rem(18)}
            fw={400}
            radius={rem(15)}
            onClick={() => router.push('/objects')}
          >
            Pievienot jaunu objektu +
          </Button>
        </div>
      </Flex>
      <div>
        <UserTraffic data={[1, 2, 3, 3, 3, 3, 3, 3]} />
      </div>
    </Flex>
  );
};
