import { useEffect, useState } from 'react';
import { Box, Flex, Text, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import cx from 'classnames';
import { VIEWS } from '../interfaces';
import { useTranslation } from '@/hooks';
import { Button, CountryCode, PasswordField, TextInput } from '@/components';
import classes from './Register.module.css';
import { Link } from '@/components/Link';

interface FormType {
  name: string;
  surname: string;
  countryCode: string;
  phone: string;
  email: string;
  password: string;
  passwordRepeat: string;
}

interface Props {
  onViewChange: (view: VIEWS) => void;
}

export const Register = ({ onViewChange }: Props) => {
  const { t } = useTranslation();
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const form = useForm({
    initialValues: {
      name: '',
      surname: '',
      countryCode: '371',
      phone: '',
      email: '',
      password: '',
      passwordRepeat: '',
    },
    validate: {
      name: (value: string) => (!value ? t('register.required') : null),
      surname: (value: string) => (!value ? t('register.required') : null),
      phone: (value: string) => (!value ? t('register.required') : null),
      countryCode: (value: string) => (!value ? t('register.required') : null),
      email: (value: string) => (!value ? t('email_warn') : null),
      password: () => (passwordErrors.length > 0 ? t('passwordwarn') : null),
      passwordRepeat: (value: string, values: FormType) =>
        !value || value !== values.password ? t('signup_pass_warn_rep') : null,
    },
  });

  const getPasswordValidation = (password: string) => {
    const errors: string[] = [];

    if (!password) errors.push('empty');

    if (password.length < 8) {
      errors.push('minLength');
    }
    if (!/[A-ZĀČĒĢĪĶĻŅŠŪŽ]/.test(password)) {
      errors.push('uppercase');
    }
    if (!/[a-zāčēģīķļņšūž]/.test(password)) {
      errors.push('lowercase');
    }
    if (!/\d/.test(password)) {
      errors.push('digits');
    }
    if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)) {
      errors.push('symbols');
    }

    setPasswordErrors(errors);
  };

  useEffect(() => {
    getPasswordValidation(form.values.password);
  }, [form.values.password]);

  const handleSubmit = async (values: FormType) => {
    console.log({ values });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box py={rem(60)} ml={rem(34)}>
        <Text fz={rem(35)} fw={600}>
          {t('signup_title')}
        </Text>
        <Text fz={rem(18)} fw={400}>
          {t('signup_intro')}
        </Text>

        <div className={classes.grid}>
          <TextInput label={t('name')} {...form.getInputProps('name')} />
          <TextInput label={t('surname')} {...form.getInputProps('surname')} />
          <Flex gap={rem(10)}>
            <CountryCode label=" " {...form.getInputProps('countryCode')} />
            <TextInput label={t('phone')} {...form.getInputProps('phone')} />
          </Flex>
          <TextInput label={t('email')} {...form.getInputProps('email')} />
        </div>
        <div className={classes.passwordBox}>
          <PasswordField
            className={classes.password}
            label={t('password')}
            {...form.getInputProps('password')}
          />
          <PasswordField
            className={classes.passwordRepeat}
            label={t('passwordrepeat')}
            {...form.getInputProps('passwordRepeat')}
          />
          <div className={classes.passwordVerify}>
            <Text c="#1F2024" mb={rem(15)}>
              {t('signup_pass_title')}
            </Text>
            <Text>{t('signup_pass_warn_1')}</Text>
            <Text>{t('signup_pass_warn_2')}</Text>
            <ul>
              <li>
                <Text
                  className={cx({
                    [classes.notValid]: passwordErrors.includes('minLength'),
                  })}
                >
                  {t('signup_pass_warn_less')}
                </Text>
              </li>
              <li>
                <Text
                  className={cx({
                    [classes.notValid]: passwordErrors.includes('uppercase'),
                  })}
                >
                  {t('signup_pass_warn_upper')}
                </Text>
              </li>
              <li>
                <Text
                  className={cx({
                    [classes.notValid]: passwordErrors.includes('lowercase'),
                  })}
                >
                  {' '}
                  {t('signup_pass_warn_lower')}
                </Text>
              </li>
              <li>
                <Text
                  className={cx({
                    [classes.notValid]: passwordErrors.includes('digits'),
                  })}
                >
                  {t('signup_pass_warn_dig')}
                </Text>
              </li>
              <li>
                <Text
                  className={cx({
                    [classes.notValid]: passwordErrors.includes('symbols'),
                  })}
                >
                  {t('signup_pass_warn_spec')}
                </Text>
              </li>
            </ul>
          </div>
        </div>
        <Flex direction="column" gap={rem(10)} mt={rem(50)}>
          <Flex align="center" gap={rem(5)}>
            <Text fz={rem(13)} fw={400}>
              {t('signup_terms_text')}
            </Text>
            <Link href="/privacy-policy">{t('signup_terms_link_1')}</Link>
            <Text fz={rem(13)} fw={400}>
              {t('signup_terms_text_2')}
            </Text>
            <Link href="/terms-of-use">{t('signup_terms_link_1')}</Link>
          </Flex>
          <Flex justify="space-between">
            <Button type="submit">{t('signup_title')}</Button>
            <Button
              variant="transparent"
              onClick={() => onViewChange(VIEWS.LOGIN)}
            >
              {t('register.alreadyHaveAccount')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </form>
  );
};
